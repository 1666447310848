(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/modal/assets/javascripts/modal.js') >= 0) return;  svs.modules.push('/components/lb-ui/modal/assets/javascripts/modal.js');
"use strict";

const {
  useEffect,
  useContext,
  useRef
} = React;
const {
  createPortal
} = ReactDOM;
const {
  ModalContext
} = svs.components.lbUi.modal;
const MODAL_IS_OPEN_CLASS = 'lb-modal-is-open';
const MODAL_CLASS = 'lb-modal-window';
const getKeyboardFocusableElements = function () {
  let element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  return element ? Array.prototype.slice.call(element.querySelectorAll('a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')).filter(el => !el.hasAttribute('disabled')) : [];
};
const setKeyboardFocusOnFirstElement = function () {
  let element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  if (element) {
    const keyboardFocusableElements = getKeyboardFocusableElements(element);
    if (keyboardFocusableElements && keyboardFocusableElements.length > 0) {
      const firstFocusable = keyboardFocusableElements.find(el => el.focus);
      if (firstFocusable) {
        firstFocusable.focus();
      }
    }
  }
};
const Modal = _ref => {
  let {
    isOpen,
    children,
    className = ''
  } = _ref;
  const windowScrollPosition = useRef();
  useEffect(() => {
    const activeElement = document.activeElement;
    if (isOpen) {
      windowScrollPosition.current = {
        left: window.scrollX,
        top: window.scrollY
      };
      window.scrollTo(0, 0);
      setKeyboardFocusOnFirstElement(document.querySelector(".".concat(MODAL_CLASS)));
      document.body.classList.add(MODAL_IS_OPEN_CLASS);
    } else if (windowScrollPosition.current) {
      window.scrollTo(windowScrollPosition.current);
    }
    return () => {
      if (isOpen) {
        document.body.classList.remove('lb-modal-is-open');
        if (windowScrollPosition.current) {
          window.scrollTo(windowScrollPosition.current);
        }
        if (activeElement && activeElement.focus) {
          activeElement.focus();
        }
      }
    };
  }, [isOpen]);
  const container = useContext(ModalContext);
  if (!isOpen || !container) {
    return null;
  }
  const modalWindowClassName = ["".concat(MODAL_CLASS, " ").concat(MODAL_IS_OPEN_CLASS)].concat(className.split(' '));
  return createPortal(React.createElement("div", {
    className: modalWindowClassName.join(' ')
  }, children), container);
};
setGlobal('svs.components.lbUi.modal.Modal', Modal);

 })(window);