(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/modal/assets/javascripts/modal-hidden.js') >= 0) return;  svs.modules.push('/components/lb-ui/modal/assets/javascripts/modal-hidden.js');
"use strict";

const ModalHidden = _ref => {
  let {
    children
  } = _ref;
  return React.createElement("div", {
    className: "lb-modal-hidden-content"
  }, children);
};
setGlobal('svs.components.lbUi.modal.ModalHidden', ModalHidden);

 })(window);