(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/modal/assets/javascripts/modal-portal.js') >= 0) return;  svs.modules.push('/components/lb-ui/modal/assets/javascripts/modal-portal.js');
"use strict";

const {
  useState,
  createContext
} = React;
const ModalContext = createContext();
const ModalPortal = _ref => {
  let {
    children,
    className
  } = _ref;
  const [modalContainer, setModalContainer] = useState();
  return React.createElement(ModalContext.Provider, {
    value: modalContainer
  }, React.createElement("div", {
    className: className,
    ref: node => {
      setModalContainer(node);
    }
  }), children);
};
setGlobal('svs.components.lbUi.modal.ModalPortal', ModalPortal);
setGlobal('svs.components.lbUi.modal.ModalContext', ModalContext);

 })(window);